

  import { computed, ref } from 'vue'
  import moment from 'moment';
  import {FilterMatchMode, FilterOperator} from "primevue/api";
  import router from "@/router";
  import {useToast} from "primevue/usetoast";
  import {useStore} from "vuex";
  import {RegisterService} from "@/models/registerService";
  import ZaloRepository from "@/services/ZaloRepository";
  import {ListService} from "@/models/listService";
  import {useRouter} from "vue-router";
  import {NghiPhepDetail} from "@/models/nghiPhepDetail";
  import zaloRepository from "@/services/ZaloRepository";
  import {ThongKeDiemDanh} from "@/models/thongKeDiemDanh";
  import axios from "axios";
  import {Department} from "@/models/department";

  export default {
    setup() {
      const toast = useToast();
      const store = useStore();
      const date = ref();
      const dateMonth = ref();
      const service = ref("");
      const router = useRouter();
      const kind = ref(0);
      const listThongKeDiemDanh = ref([] as ThongKeDiemDanh[]);
      const listThongKeDiemDanhTemp = ref([] as ThongKeDiemDanh[]);
      const loadingData = ref(false);
      const listDepartment =  ref([] as Department[]);
      const idDepartment = ref(0);
      const nameDepart = ref("");
      const showDetail = ref(false);
      const thongKeDiemDanh = ref([] as ThongKeDiemDanh[]);
      const listLate = ref([
        { label: 'CheckIn sáng trễ', value: 1 },
        { label: 'CheckIn chiều trễ', value: 2 },
      ]);
      const listDepart = ref([] as Department[]);

      const getListDepartments = () => {
          zaloRepository.getListDepartments()
              .then((response) => {
                listDepartment.value = response.data;
              })
              .catch(err => {
                toast.add({
                  severity: 'error',
                  summary: 'Lỗi',
                  detail:err.response.data,
                  life: 2500
                })})
      };
      getListDepartments();

      const selectCalendar = () => {
        loadingData.value = false;
        if((date.value != null))
        {
          loadingData.value = true;
          listThongKeDiemDanh.value = [];
          listThongKeDiemDanhTemp.value = [];
          listDepart.value = [];
          idSelect.value = 0;
          nameDepart.value = "";
          downloadFile.value = false;
          zaloRepository.getListThongKeDiemDanh(date.value/1000)
              .then((response) => {
                listThongKeDiemDanh.value = response.data;
                loadingData.value = false;
                console.log("@@@@@@@@@################ list: " + JSON.stringify(listThongKeDiemDanh.value));
                var index = 1;
                listThongKeDiemDanh.value.forEach(x => {
                  x.id = index;
                  index++;
                })
                listThongKeDiemDanhTemp.value = listThongKeDiemDanh.value;
                listThongKeDiemDanh.value.forEach(x => {
                  var checkDepart = true
                  listDepart.value.forEach(y => {
                    if(y.name == x.team)
                    {
                      checkDepart = false;
                    }
                  })
                  if(checkDepart){
                    listDepart.value.push({name: x.team});
                  }
                })
              })
              .catch(err => {
                toast.add({
                  severity: 'error',
                  summary: 'Lỗi',
                  detail:err.response.data,
                  life: 2500
                })})
        }
      }

      //selectCalendar();

      const clearCalendar = () => {
        listThongKeDiemDanh.value = [];
      }

      const formatDateTime = (date) => {
        if((date == null)||(date == ''))
        {
          return "chưa có thông tin"
        }
        else
        return moment(String(date)).format('HH:mm DD/MM/YYYY');
      };


      const validDate = (registerService: RegisterService) => {
        return registerService.timeConfirm;
      }

      const clearDate = () => {
        console.log("@@@@@@@@@################ clearDate");
        date.value = new Date();
      }

      const status = (id, xn) => {
        if(xn) return "Cho phép nghỉ";
        else {
          if(id > 0) return "Không cho phép nghỉ";
          else return "Chưa xác nhận";
        }
      }
      const filters = ref({
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
      });

      const clearFilter = () => {
        initFilters();
      };

      const initFilters = () => {
        filters.value = {
          'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        }
      };

      const checkInMorningCount = computed(() => {
        var id = 0;
        listThongKeDiemDanh.value.forEach(x => {
          if(x.checkInMorningImage != null)
          {
            console.log("@@@@@@@@@################ null: ");
            id = id + 1;
          }
        })
        console.log("@@@@@@@@@################ id: " + id);
        return "Checkin sáng: " + id;
      })

      const checkOutMorningCount = computed(() => {
        var id = 0;
        listThongKeDiemDanh.value.forEach(x => {
          if(x.checkOutMorningImage != null)
          {
            console.log("@@@@@@@@@################ null: ");
            id = id + 1;
          }
        })
        console.log("@@@@@@@@@################ id: " + id);
        return "Checkout sáng: " + id;
      })

      const checkInAfternoonCount = computed(() => {
        var id = 0;
        listThongKeDiemDanh.value.forEach(x => {
          if(x.checkInAfternoonImage != null)
          {
            console.log("@@@@@@@@@################ null: ");
            id = id + 1;
          }
        })
        console.log("@@@@@@@@@################ id: " + id);
        return "Checkin chiều: " + id;
      })

      const checkOutAfternoonCount = computed(() => {
        var id = 0;
        listThongKeDiemDanh.value.forEach(x => {
          if(x.checkOutAfternoonImage != null)
          {
            console.log("@@@@@@@@@################ null: ");
            id = id + 1;
          }
        })
        console.log("@@@@@@@@@################ id: " + id);
        return "Checkout chiều: " + id;
      })

      const downloadFile = ref(false);
      const loadingBar = ref(false);
      const fileName = ref("");
      const loadingExport = ref(false);
      const idSelect = ref(0);
      const exportFile = () => {
        loadingExport.value = true;
        downloadFile.value = false;
        zaloRepository.exportListCheckIn(listThongKeDiemDanh.value)
            .then((response) => {
              fileName.value = response.data;
              toast.add({
                severity: 'success',
                summary: 'Xuất dữ liệu',
                detail: 'Xuất dữ liệu thành công',
                life: 2000
              });
              loadingExport.value = false;
              downloadFile.value = true;
              // router.push({
              //   name: 'home'
              // });
            })
            .catch(err => {
              loadingBar.value = false;
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2000
              });
            });
      }

      const downLoadFileExport = (file, kind) => {
        axios({
          url: '../api/webhook/downloadListCheckIn/' + file,
          method: 'GET',
          responseType: 'blob',
        })
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data], {type: response.data.type}));
              const link = document.createElement('a');
              link.href = url;
              if(kind == 0) {
                link.setAttribute('download', "DanhSachCheckIn" + "(" + moment(String(date.value)).format('DD/MM/YYYY') + ")" + ".xlsx");
              }
              else{
                link.setAttribute('download', "DanhSachCheckInThang" + "(" + moment(String(dateMonth.value)).format('MM/YYYY') + ")" + ".xlsx");
              }
              document.body.appendChild(link);
              link.click();
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2500
              })});
      };
      const fileNameMonth = ref("");

      const exportFileMonth = () => {
        loadingExport.value = true;
        downloadFile.value = false;
        if((dateMonth.value != null)) {
          loadingData.value = true;
          zaloRepository.getListThongKeDiemDanhMonth(dateMonth.value / 1000, idDepartment.value)
              .then((response) => {
                fileNameMonth.value = response.data;
                toast.add({
                  severity: 'success',
                  summary: 'Xuất dữ liệu',
                  detail: 'Xuất dữ liệu thành công',
                  life: 2000
                });
                loadingExport.value = false;
                downloadFile.value = true;
                loadingData.value = false;
                // router.push({
                //   name: 'home'
                // });
              })
              .catch(err => {
                loadingBar.value = false;
                toast.add({
                  severity: 'error',
                  summary: 'Lỗi',
                  detail: err.response.data,
                  life: 2000
                });
              });
        }
      }

      const showDetailThongKe = (id) => {
        console.log("@@@@@@@@@################ showDetailThongKe id: " + id);
        showDetail.value = true;
        thongKeDiemDanh.value = [];
        console.log("@@@@@@@@@################ showDetailThongKe showDetail: " + showDetail.value);
        listThongKeDiemDanh.value.forEach(x => {
          if(x.id == id){
            thongKeDiemDanh.value.push(x);
          }
        })
        console.log("@@@@@@@@@################ showDetailThongKe thongKeDiemDanh: " + JSON.stringify(thongKeDiemDanh.value));

      }

      const selectKind = () => {
        if(idSelect.value == 1) {
          listThongKeDiemDanh.value = listThongKeDiemDanhTemp.value.filter(x => x.checkInMorningLatestImage != null);
        }
        else if(idSelect.value == 2) {
          listThongKeDiemDanh.value = listThongKeDiemDanhTemp.value.filter(x => x.checkInAfternoonLatestImage != null);
        }
        else {
            listThongKeDiemDanh.value = listThongKeDiemDanhTemp.value;
        }

        if(nameDepart.value.length > 0)
        {
          listThongKeDiemDanh.value = listThongKeDiemDanh.value.filter(x => x.team == nameDepart.value);
        }

      }


      return {
        formatDateTime,
        listThongKeDiemDanh,
        date,
        selectCalendar,
        clearCalendar,
        service,
        validDate,
        kind,
        clearDate,
        status,
        clearFilter,
        filters,
        checkInMorningCount,
        checkOutMorningCount,
        checkInAfternoonCount,
        checkOutAfternoonCount,
        exportFile,
        downLoadFileExport,
        loadingExport,
        downloadFile,
        loadingData,
        dateMonth,
        exportFileMonth,
        fileName,
        fileNameMonth,
        idDepartment,
        listDepartment,
        showDetailThongKe,
        showDetail,
        thongKeDiemDanh,
        selectKind,
        listLate,
        idSelect,
        listDepart,
        nameDepart
      }
    }

  }
