

import { computed, ref } from 'vue'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {useStore} from "vuex";
import {ListCustomer} from "@/models/listCustomer";
import zaloRepository from "@/services/ZaloRepository";
import {FilterMatchMode} from "primevue/api";
import {ManagerCheckinCreate} from "@/models/managerCheckinCreate";
import {ManagerCheckin} from "@/models/managerCheckin";
import {StatisticalCheckIn} from "@/models/statisticalCheckIn";

export default {

  setup(){
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const listCustomer = ref([] as ListCustomer[]);
    const managerCheckin = ref({} as ManagerCheckinCreate);
    const listManagerCheckin = ref([] as ManagerCheckin[]);
    const statisticalCheckIn = ref({} as StatisticalCheckIn);
    const listStatisticalCheckIn = ref([] as StatisticalCheckIn[]);

    const loadListMember = () => {
      zaloRepository.getListCustomer()
          .then((response) => {
            listCustomer.value = response.data;
            loadListManager();
            loadListStatistical();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:'Lỗi khi tải danh sách công dân',
              life: 2000
            });
          });
    }

    const loadListStatistical = () => {
      zaloRepository.listStatisticalCheckIn()
          .then((response) => {
            listStatisticalCheckIn.value = response.data;
            listStatisticalCheckIn.value.forEach(x => {
              listCustomer.value.filter(y => {
                if(y.id == x.zaloId) x.imageLink = y.imageUrl;
              })
            });
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:'Lỗi khi tải danh sách công dân',
              life: 2000
            });
          });
    }

    loadListMember();

    const loadListManager = () => {
      zaloRepository.getListManagerOfCheckin()
          .then((response) => {
            listManagerCheckin.value = response.data;
            listManagerCheckin.value.forEach(x => {
              listCustomer.value.filter(y => {
                if(y.id == x.zaloId) x.imageLink = y.imageUrl;
              })
            });
            console.log("@@################### loadListManager listManagerCheckin : " + JSON.stringify(listManagerCheckin.value))
          })
          .catch(err => {
            // toast.add({
            //   severity: 'error',
            //   summary: 'Lỗi',
            //   detail:'Lỗi khi tải danh sách nhận góp ý',
            //   life: 2000
            // });
          });
    }



    const addMember = () => {
      listCustomer.value.forEach(x => {
        if(x.id == managerCheckin.value.zaloId){
          managerCheckin.value.fullName = x.name;
        }
      })
      zaloRepository.addManagerOfCheckin(managerCheckin.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Thêm thành viên vào nhóm thành công',
              life: 2000
            });
            loadListManager();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    const deleteManager = (id: number) => {
      zaloRepository.deleteManagerOfCheckin(id)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Xóa thành viên ra khỏi nhóm thành công',
              life: 2000
            });
            loadListManager();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    const addStatisticalCheckIn = () => {
      listCustomer.value.forEach(x => {
        if(x.id == statisticalCheckIn.value.zaloId){
          statisticalCheckIn.value.fullName = x.name;
        }
      })
      zaloRepository.createStatisticalCheckIn(statisticalCheckIn.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Thêm thành viên vào nhóm thành công',
              life: 2000
            });
            loadListStatistical();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    const deleteStatistical = (id: number) => {
      zaloRepository.deleteStatisticalCheckIn(id)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Xóa thành viên ra khỏi nhóm thành công',
              life: 2000
            });
            loadListStatistical();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    return {
      error,
      managerCheckin,
      listCustomer,
      addMember,
      deleteManager,
      listManagerCheckin,
      filters,
      clearFilter,
      initFilters,
      addStatisticalCheckIn,
      deleteStatistical,
      statisticalCheckIn,
      listStatisticalCheckIn,
    }
  }
}

