

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {Register} from "../models/register";
import {useStore} from "vuex";
import TeamRepository from "../services/TeamRepository";
import {Team} from "@/models/team.models";
import {ListCustomer} from "@/models/listCustomer";
import ZaloRepository from "@/services/ZaloRepository";
import {EmployeeCreate} from "@/models/employeeCreate";
import zaloRepository from "@/services/ZaloRepository";
import {GroupCreate} from "@/models/groupCreate";
import {Group} from "@/models/group";
import {MemberInGroupCreate} from "@/models/memberInGroupCreate";
import {MemberInGroup} from "@/models/memberInGroup";
import {ManagerOfGroupCreate} from "@/models/managerOfGroupCreate";
import {ManagerOfGroup} from "@/models/managerOfGroup";
import {Department} from "@/models/department";
import {EmployeeCheckIn} from "@/models/employeeCheckIn";
import {EmployeeLogin} from "@/models/employeeLogin";
import {useConfirm} from "primevue/useconfirm";
import axios from "axios";
import moment from "moment";
import {FileDetail} from "@/models/fileDetail.models";

export default {

  setup(){
    const router = useRouter();
    const register = ref({} as Register);
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const group = ref({} as GroupCreate);
    const department = ref({} as Department);
    const listDepartment = ref([] as Department[]);
    const member = ref({} as MemberInGroupCreate);
    const listMember = ref([] as MemberInGroup[]);
    const manager = ref({} as ManagerOfGroupCreate);
    const listManager = ref([] as ManagerOfGroup[]);
    const listCustomer = ref([] as ListCustomer[]);
    const employeeCheckin = ref({} as EmployeeCheckIn);
    const listEmployee = ref([] as EmployeeCheckIn[]);
    const employeeLogin = ref({} as EmployeeLogin);
    const listEmployeeLogin = ref([] as EmployeeLogin[]);
    const confirm = useConfirm();
    const employeeCheckInUpdate = ref({} as EmployeeCheckIn);
    const updateEmployee = ref(false);
    const updateEmployeeLog = ref(false);
    const employeeLoginUpdate = ref({} as EmployeeLogin);

    const getListDepart = () => {
      ZaloRepository.getListDepartments()
          .then((response) => {
            listDepartment.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const getListEmployee = () => {
      ZaloRepository.getListEmployeeInHospital()
          .then((response) => {
            listEmployee.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const getListEmployeeLogin = () => {
      ZaloRepository.getListEmployeeLogin()
          .then((response) => {
            listEmployeeLogin.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }
    getListEmployee();
    getListDepart();
    getListEmployeeLogin();

    const createDepartment = () => {
      ZaloRepository.createDepartment(department.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Tạo khoa/phòng',
              detail: 'Tạo khoa/phòng thành công',
              life: 2000
            });
            getListDepart();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const createEmployeeLogin = () => {
      listEmployee.value.forEach(x => {
        if(x.phoneNumber == employeeLogin.value.phoneNumber){
          employeeLogin.value.fullname = x.fullName;
        }
      })
      ZaloRepository.createEmployeeLogin(employeeLogin.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Tạo tài khoản',
              detail: 'Tạo tài khoản thành công',
              life: 2000
            });
            getListEmployeeLogin();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const createEmployee = () => {
      listDepartment.value.forEach(x => {
        if(x.id == employeeCheckin.value.idDepartments){
          employeeCheckin.value.departmentsName = x.name;
        }
      })

      ZaloRepository.createEmployeeInHospital(employeeCheckin.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Tạo nhân viên',
              detail: 'Tạo nhân viên thành công',
              life: 2000
            });
            getListEmployee();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }
    const deleteEmployeeInHospital = (id: number) => {
      confirm.require({
        group: 'templating',
        header: 'Xóa nhân viên',
        message: 'Xác nhận xóa nhân viên ra khỏi danh sách. Chú ý tài khoản đăng nhập của nhân viên cũng bị xóa theo!',
        icon: 'pi pi-exclamation-circle',
        acceptLabel: 'Xóa',
        rejectLabel: 'Hủy',
        accept: () => {
          // toast.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
          ZaloRepository.deleteEmployeeInHospital(id)
              .then((response) => {
                toast.add({
                  severity: 'info',
                  summary: 'Xóa nhân viên',
                  detail: 'Xóa nhân viên thành công',
                  life: 2000
                });
                getListEmployee();
                getListEmployeeLogin();
              })
              .catch(err => {
                toast.add({
                  severity: 'error',
                  summary: 'Lỗi',
                  detail:err.response.data,
                  life: 2000
                });
              });
        },
        reject: () => {
          // toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        }
      })
    }

    const getEmployeeInHospitalById = (id) => {
      ZaloRepository.getEmployeeInHospitalById(id)
          .then((response) => {
            employeeCheckInUpdate.value = response.data;
            updateEmployee.value = true;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const updateEmployeeInHospital = () => {
      listDepartment.value.forEach(x => {
        if(x.id == employeeCheckInUpdate.value.idDepartments){
          employeeCheckInUpdate.value.departmentsName = x.name;
        }
      })
      ZaloRepository.updateEmployeeInHospital(employeeCheckInUpdate.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail:'Cập nhật thông tin thành công',
              life: 2000
            });
            updateEmployee.value = false;
            getListEmployee();
            getListEmployeeLogin();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const deleteEmployeeLogin = (id: number) => {
      confirm.require({
        group: 'templating',
        header: 'Xóa tài khoản nhân viên',
        message: 'Xác nhận xóa tài khoản nhân viên.',
        icon: 'pi pi-exclamation-circle',
        acceptLabel: 'Xóa',
        rejectLabel: 'Hủy',
        accept: () => {
          // toast.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
          ZaloRepository.deleteEmployeeLogin(id)
              .then((response) => {
                toast.add({
                  severity: 'info',
                  summary: 'Xóa tài khoản nhân viên',
                  detail: 'Xóa tài khoản nhân viên thành công',
                  life: 2000
                });
                getListEmployeeLogin();
              })
              .catch(err => {
                toast.add({
                  severity: 'error',
                  summary: 'Lỗi',
                  detail:err.response.data,
                  life: 2000
                });
              });
        },
        reject: () => {
          // toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        }
      })
    }

    const getEmployeeLoginById = (id) => {
      ZaloRepository.getEmployeeLoginById(id)
          .then((response) => {
            employeeLoginUpdate.value = response.data;
            updateEmployeeLog.value = true;
            employeeLoginUpdate.value.password = "";
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const updateEmployeeLogin = () => {
      listEmployee.value.forEach(x => {
        if(x.phoneNumber == employeeLoginUpdate.value.phoneNumber){
          employeeLoginUpdate.value.fullname = x.fullName;
        }
      })
      ZaloRepository.updateEmployeeLogin(employeeLoginUpdate.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail:'Cập nhật thông tin thành công',
              life: 2000
            });
            updateEmployeeLog.value = false;
            getListEmployeeLogin();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }
    const downLoadFileExport = () => {
      axios({
        url: '../api/webhook/downloadListEmployee/',
        method: 'GET',
        responseType: 'blob',
      })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: response.data.type}));
            const link = document.createElement('a');
            link.href = url;
              link.setAttribute('download', "DanhSachNhanVien.xlsx");

            document.body.appendChild(link);
            link.click();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2500
            })});
    };

    const listLink = ref([] as string[]);
    let listFileName = ref([] as FileDetail[]);
    const loadingBar = ref(false);
    const upload = () => {
      {
        listLink.value = [];
        var files = new FormData();
        listFileName.value.forEach(x =>
        {
          files.append("formFiles", x.value, x.nameFile);
        });
        loadingBar.value = true;
        axios.post(`../api/File/UploadFileEmployees`, files,
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
              }
            }).then(response => {
          loadingBar.value = false;
          listFileName.value = [];
          toast.add({
            severity: 'success',
            summary: 'Thành công',
            detail:'Đăng tải danh sách thành công',
            life: 2000
          });
        })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2500
              })});
      }
    };

    const fileChange = (fileList) => {
      var leng = fileList.length;
      for (var i = 0; i < leng; i++)
      {
        var filetemp = ref({} as FileDetail);
        filetemp.value.nameFile = fileList[i].name;
        filetemp.value.value = fileList[i];
        listFileName.value.push(filetemp.value);
      }
    };

    return {
      group,
      createDepartment,
      member,
      listMember,
      manager,
      listManager,
      listCustomer,
      department,
      listDepartment,
      employeeCheckin,
      createEmployee,
      listEmployee,
      employeeLogin,
      listEmployeeLogin,
      createEmployeeLogin,
      deleteEmployeeInHospital,
      getEmployeeInHospitalById,
      employeeCheckInUpdate,
      updateEmployeeInHospital,
      updateEmployee,
      deleteEmployeeLogin,
      getEmployeeLoginById,
      updateEmployeeLogin,
      updateEmployeeLog,
      employeeLoginUpdate,
      downLoadFileExport,
      upload,
      listLink,
      listFileName,
      loadingBar,
      fileChange,
    }
  }
}

