

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {Register} from "../models/register";
import {useStore} from "vuex";
import TeamRepository from "../services/TeamRepository";
import {Team} from "@/models/team.models";
import {ListCustomer} from "@/models/listCustomer";
import ZaloRepository from "@/services/ZaloRepository";
import {EmployeeCreate} from "@/models/employeeCreate";
import zaloRepository from "@/services/ZaloRepository";
import {GroupCreate} from "@/models/groupCreate";
import {Group} from "@/models/group";
import {MemberInGroupCreate} from "@/models/memberInGroupCreate";
import {MemberInGroup} from "@/models/memberInGroup";
import {ManagerOfGroupCreate} from "@/models/managerOfGroupCreate";
import {ManagerOfGroup} from "@/models/managerOfGroup";
import {Department} from "@/models/department";
import {EmployeeCheckIn} from "@/models/employeeCheckIn";
import {EmployeeLogin} from "@/models/employeeLogin";
import {GroupCheckIn} from "@/models/groupCheckInCreate";
import {UpdateEmployeeGroup} from "@/models/updateEmployeeGroup";

export default {

  setup(){
    const router = useRouter();
    const register = ref({} as Register);
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const groupCheckIn = ref({} as GroupCheckIn);
    const listGroupCheckIn = ref([] as GroupCheckIn[]);
    const listEmployeeCheckIn = ref([] as EmployeeCheckIn[]);
    const listEmployeeCheckInGroup = ref([] as EmployeeCheckIn[]);
    const updateEmployee = ref({} as UpdateEmployeeGroup);
    const nameGroup = ref("");

    const getListGroup = () => {
      ZaloRepository.getListGroupCheckIn()
          .then((response) => {
            listGroupCheckIn.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }
    const getListEmployee = () => {
      ZaloRepository.getListEmployeeInHospital()
          .then((response) => {
            listEmployeeCheckIn.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }
    const getListEmployeeGroup = (id) => {

      ZaloRepository.getListEmployeeGroup(id)
          .then((response) => {
            listEmployeeCheckInGroup.value = response.data;
            listGroupCheckIn.value.forEach(x => {
              if(x.id == id)
                nameGroup.value = x.groupName;
            })
            listEmployeeCheckInGroup.value.forEach(x => {
              x.groupName = nameGroup.value;
            })
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }
    getListGroup();
    getListEmployee();

    const createGroupCheckIn = () => {
      ZaloRepository.createGroupCheckIn(groupCheckIn.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Tạo nhóm Checkin',
              detail: 'Tạo nhóm Checkin thành công',
              life: 2000
            });
            getListGroup();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const updateEmployeeGroups = () => {
      ZaloRepository.updateEmployeeGroups(updateEmployee.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thêm nhân viên vào nhóm',
              detail: 'Thêm nhân viên vào nhóm thành công',
              life: 2000
            });
            getListGroup();
            getListEmployeeGroup(updateEmployee.value.idGroup);
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const deleteEmployeeGroup = (id) => {
      ZaloRepository.deleteEmployeeGroup(id)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Xóa nhân viên',
              detail: 'Xóa nhân viên ra khỏi nhóm thành công',
              life: 2000
            });
            getListGroup();
            getListEmployeeGroup(updateEmployee.value.idGroup);
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const deleteGroupCheckIn = (id) => {
      ZaloRepository.deleteGroupCheckIn(id)
          .then((response) => {
            toast.add({
              severity: 'info',
              summary: 'Xóa nhóm',
              detail: 'Xóa nhóm thành công',
              life: 2000
            });
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    };

    return {
      groupCheckIn,
      listGroupCheckIn,
      createGroupCheckIn,
      listEmployeeCheckIn,
      listEmployeeCheckInGroup,
      updateEmployee,
      updateEmployeeGroups,
      deleteEmployeeGroup,
      getListEmployeeGroup,
    }
  }
}

